<template>
    <SmartTable url="/settings/sodalizi-tipi/index" base_path="/settings/sodalizi-tipi"></SmartTable>
</template>
<script>
 import SmartTable from "@/view/components/SmartTable.vue";
 import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

 export default {
     components: {
         SmartTable
     },
     mounted() {
         this.$store.dispatch(SET_BREADCRUMB, [
             { title: "Impostazioni", route: "/settings" },
             { title: "Tipi Sodalizi", route: "/settings/sodalizi-tipi/index" },
             { title: "Ricerca Tipi Sodalizi" }
        ]);
    },
 };
</script>
